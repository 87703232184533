<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-dark position-relative border-bottom nav-border">
      <div class="container-fluid px-xl-4">
        <a class="navbar-brand px-0 mx-0 me-xl-5" href="#">
          <p class="px-0 mx-0 py-0 my-0">
            <span class="nav-logo-font">ИТ Сфера</span>
          </p>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul v-if="isLoggedIn" class="navbar-nav nav-items">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Главная</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="https://st-agrosoyuz.ru/copyes">Бэкапы</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="logout">Выйти</a>
            </li>
          </ul>
          <ul v-else class="navbar-nav nav-items">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Главная</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/login">Войти</router-link>
            </li>
          </ul>
        </div>  
      </div>
    </nav>
  </header>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavBar',
  computed: {
      isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('logOut');
      this.$router.push('/');
    }
  },
});
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>