<template>
  <div class="background">
    <div id="app">
      <NavBar v-if="!$route.meta.hideNavbar"  />
      <div class="main container">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
export default {
  components: {
    NavBar
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.background {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180.00deg, rgb(5, 11, 8),rgb(7, 13, 9) 65.249%,rgb(14, 38, 23) 100%);
}
</style>
