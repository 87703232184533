<template>
  <div >
    <section>
      <h1>dashboard</h1>
      <hr/><br/>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
// import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  name: 'Dashboard',
});
</script>
