<template>
  <section>
    <div className="form-wrapper">
      <div className="form-box">
        <div className="form-value">
          <form @submit.prevent="submit">
            <h2 className="form-label">Вход</h2>
            <div className="inputbox">
              <input className="inputbox-input" type="text" name="username" v-model="form.username" required/>
              <label className="inputbox-label" htmlFor="">Логин</label>
            </div>
            <div className="inputbox">
              <input className="inputbox-input" type="password" name="password" v-model="form.password" required/>
              <label className="inputbox-label" htmlFor="">Пароль</label>
            </div>
            <button className="button" type='submit'>Войти</button>
          </form>
        </div>
      </div> 
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password:'',
      }
    };
  },
  methods: {
    ...mapActions(['logIn']),
    async submit() {
      const User = new FormData();
      User.append('username', this.form.username);
      User.append('password', this.form.password);
      await this.logIn(User);
      this.$router.push('/dashboard');
    }
  }
});
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.section {
  width: 100%;
}
.form-wrapper{
    margin: 0;
    padding: 0;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: url('../assets/background.jpg')no-repeat; */
    background-position: center;
    background-size: cover;
}
.form-box{
  position: relative;
  width: 400px;
  height: 450px;
  background: transparent;
  border: 2px solid rgba(255,255,255,0.5);
  border-radius: 20px;
  backdrop-filter: blur(70px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-label{
  font-size: 2em;
  color: #fff;
  text-align: center;
}
.inputbox{
  position: relative;
  margin: 30px 0;
  width: 310px;
  border-bottom: 2px solid #fff;
}
.inputbox-label{
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  color: #fff;
  font-size: 1em;
  pointer-events: none;
  transition: .5s;
}
.inputbox-input {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  padding:0 35px 0 5px;
  color: #fff;
}
.inputbox-input:focus ~ label,
.inputbox-input:valid ~ label{
  top: -5px;
}
.button{
  width: 100%;
  height: 40px;
  border-radius: 40px;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
}
.button:hover{
  background: #ffffffe0;
}
.button:active{
  background: #ffffff9c;
}
</style>